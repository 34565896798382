
/*eslint-disable*/

function Footer(){
    return (
        <>
        {/* footer section Main Left  */}
        <footer id="footer">
            <div className="footer_top">
                <a href="#" className="resumo_fn_totop"><span></span></a>
            </div>
            <div className="footer_content">
                <div className="container">
                    <p>Copyright © 2021. All rights reserved. <br />
                    Designed &amp; Developed by <a className="fn__link" href="https://abs-trak.com" target="_blank">ABS-TRAK</a></p>
                </div>
            </div>
        </footer>
        </>
    )
}

export default Footer;