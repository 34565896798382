
/*eslint-disable*/


function MainRightPart() {
  return (
    <>
      {/* Main Right Part */}
      <div className="resumo_fn_right">
        {/* <!-- Menu Triggger --> */}
        <a href="#" className="menu_trigger">
          <span className="text">Menu</span>
          <span className="hamb">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </a>
        {/* <!-- /Menu Triggger --> */}

        {/* Panel Content */}
        <div className="right_in">
          <div className="right_top">
            <div className="border1"></div>
            <div className="border2"></div>

            <div className="img_holder">
              <img src="assets/img/thumb/sha.jpg" alt="" />
              <div className="abs_img" data-bg-img="assets/img/thumb/sha.jpg"></div>
            </div>
            <div className="title_holder">
              <h5>Hi There! I am</h5>
              <h3>
                <span className="animated_title">
                  <span className="title_in">Shalot Ariho</span>
                  <span className="title_in">Web Developer</span>
                  {/* <span className="title_in">Freelancer</span> */}
                  <span className="title_in">Database Admin</span>
                </span>
              </h3>
            </div>
          </div>
          <div className="right_bottom">
            <a href="#contact">
              <span className="circle"></span>
              <span className="text">
                I’m available for a freelance job. Hire me
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainRightPart;
