
/*eslint-disable */

function ContactSection() {
  return (
    <>
    <section id="contact">
                <div className="container">
                    <div className="roww resumo_fn_contact">

                        {/* <!-- Main Title --> */}
                        <div className="resumo_fn_main_title">
                            <h3 className="subtitle">Contact</h3>
                            <h3 className="title">Get In Touch</h3>
                            <p className="desc">If you have any suggestion, project or even you want to say “hello”, please fill out the form below and I will reply you shortly.</p>
                        </div>
                        {/* <!-- /Main Title --> */}

                        {/* <!-- Contact Form --> */}
                        <form className="contact_form" action="/" method="post" autoComplete="off" data-email="frenifyteam@gmail.com">

                            
                                {/* Don't remove below code in avoid to work contact form properly.
                                You can chance dat-success value with your one. It will be used when user will try to contact via contact form and will get success message.
                             */}
                            <div className="success" data-success="Your message has been received, we will contact you soon."></div>
                            <div className="empty_notice"><span>Please Fill Required Fields!</span></div>

                            <div className="items_wrap">
                                <div className="items">
                                    <div className="item half">
                                        <div className="input_wrapper">
                                            <input id="name" type="text" />
                                            <span className="moving_placeholder">Name *</span>
                                        </div>
                                    </div>
                                    <div className="item half">
                                        <div className="input_wrapper">
                                            <input id="email" type="email" />
                                            <span className="moving_placeholder">Email *</span>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="input_wrapper">
                                            <input id="phone" type="text" />
                                            <span className="moving_placeholder">Phone</span>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="input_wrapper">
                                            <textarea id="message"></textarea>
                                            <span className="moving_placeholder">Message</span>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <a id="send_message" href="#">Send Message</a>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {/* <!-- /Contact Form --> */}

                        {/* <!-- Contact Info --> */}
                        <div className="resumo_fn_contact_info">
                            <p>Address</p>
                            <h3>69 street , kampala, Uganda</h3>
                            <p>Phone</p>
                            <h3><a href="tel:+7068980751">(+256) 783975461</a></h3>
                            <p><a className="fn__link" href="mailto:arihoshalot1@gmail.com"><span className="__cf_email__" data-cfemail="593f2b3c37303f202d3c3834193e34383035773a3634">[email&#160;protected]</span></a></p>
                        </div>
                        {/* <!-- /Contact Info --> */}

                    </div>
                </div>
            </section>
            {/* <!-- /Contact Section --> */}
    </>
  );
}

export default ContactSection;