/* eslint-disable */


function CustomerService() {
  return (
    <>
      <section id="customers">
        <div className="container">
          <div className="roww">
            {/* <!-- Main Title --> */}
            <div className="resumo_fn_main_title">
              <h3 className="subtitle">Customers</h3>
              <h3 className="title">Happy People</h3>
            </div>
            {/* <!-- /Main Title --> */}

            {/* <!-- Partners --> */}
            <div className="resumo_fn_partners">
              <ul>
                <li>
                  <a href="https://envato.com/" target="_blank">
                    <img src="/assets/img/partners/1.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://frenify.com/" target="_blank">
                    <img src="assets/img/partners/2.png" alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://themeforest.net/item/rewall-personal-portfolio-template/34316546"
                    target="_blank"
                  >
                    <img src="assets/img/partners/3.png" alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://themeforest.net/item/artemiz-blog-podcast-wordpress-theme/28455063"
                    target="_blank"
                  >
                    <img src="assets/img/partners/4.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <img src="assets/img/partners/5.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <img src="assets/img/partners/6.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <img src="assets/img/partners/7.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <img src="assets/img/partners/8.png" alt="" />
                  </a>
                </li>
              </ul>
            </div>
            {/* <!-- /Partners --> */}

            {/* <!-- Testimonials --> */}
            <div className="resumo_fn_testimonials">
              <div className="my__nav">
                <a href="#" className="prev">
                  <span></span>
                </a>
                <a href="#" className="next">
                  <span></span>
                </a>
              </div>
              <div className="owl-carousel">
                <div className="item">
                  <div className="title_holder">
                    <p className="desc">
                      “ They really nailed it. she is one of the best young web developers I
                      have seen in a long time. Very nice designs and creative  with lots of
                      customization on projects. Very good at project Delivery  ”
                    </p>
                    <h3 className="title">Iden Tumuhwire</h3>
                    <h3 className="subtitle">Freelancer &amp; software Developer </h3>
                  </div>
                </div>
                <div className="item">
                  <div className="title_holder">
                    <p className="desc">
                      “ she is very good in art creation and trouble shooting 
                      when it comes to code and production  ”
                    </p>
                    <h3 className="title">Anna Barbera</h3>
                    <h3 className="subtitle">junior Web developer </h3>
                  </div>
                </div>
                <div className="item">
                  <div className="title_holder">
                    <p className="desc">
                      “ she is Good at hardware and software maintainence , not forgetting 
                      networks and databases  Great!!! person to work with  ”
                    </p>
                    <h3 className="title">Dana Atkins</h3>
                    <h3 className="subtitle">Client </h3>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /Testimonials --> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default CustomerService;
