
/* eslint-disable */


function RightHiddenNav(){
    return(
        <>
        <a href="#" class="resumo_fn_nav_overlay"></a>
	<div class="resumo_fn_navigation">
		<a href="#" class="closer"></a>
		
		{/* <!-- Navigation Content --> */}
		<div class="nav_in">
			
			<nav id="nav">
				<h3 class="label">Menu</h3>
				<ul>
					<li><a href="#home">Home</a></li>
					<li><a href="#about">About</a></li>
					<li><a href="#portfolio">Portfolio</a></li>
					<li><a href="#services">Services</a></li>
					<li><a href="#customers">Customers</a></li>
					<li><a href="#news">News &amp; Tips</a></li>
					<li><a href="#contact">Contact</a></li>
				</ul>
			</nav>
			
			<div class="nav_footer">
				<div class="social">
					<ul>
						<li><a href="#" target="_blank"><img src="assets/svg/social/twitter.svg" alt="" class="fn__svg" /></a></li>
						<li><a href="#" target="_blank"><img src="assets/svg/social/facebook.svg" alt="" class="fn__svg" /></a></li>
						<li><a href="#" target="_blank"><img src="assets/svg/social/instagram.svg" alt="" class="fn__svg" /></a></li>
						<li><a href="#" target="_blank"><img src="assets/svg/social/pinterest.svg" alt="" class="fn__svg" /></a></li>
						<li><a href="#" target="_blank"><img src="assets/svg/social/behance.svg" alt="" class="fn__svg" /></a></li>
					</ul>
				</div>
				<div class="copyright">
					<a href="https://abs-trak.com" target="_blank">Developed by Shalot Ariho</a>
				</div>
			</div>
			
		</div>
		{/* <!-- /Navigation Content --> */}
		
	</div>
        </>
    )
}

export default RightHiddenNav;