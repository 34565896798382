import AboutSection from "../components/MainSections/AboutSection";
import PortfolioSection from "../components/MainSections/PortfolioSection";
import ContactSection from "../components/MainSections/ContactSection";
import CustomerService from "../components/MainSections/CustomerService";
import ServicesSection from "../components/MainSections/ServiceSection";
import Footer from "../components/MainSections/Footer";
import NewsSection from "../components/MainSections/NewSection";
import MainRightPart from "../components/MainSections/MainRightPart";
import Modalbox from "../components/Modalbox";
import RightHiddenNav from "../components/RightHiddenNav";

function Home() {
  return (
    <>
      <div className="resumo_fn_wrapper">
        <Modalbox />
        <div className="resumo_fn_content">
          {/* <!-- Main Left Part --> */}
          <div className="resumo_fn_left">
            {/* <!-- Page --> */}
            <div className="resumo_fn_page">
              {/* <!-- Home Section --> */}
              <section id="home">
                <div className="container">
                  <div className="roww">
                    {/* <!-- Main Title --> */}
                    <div className="resumo_fn_main_title">
                      <h3 className="subtitle">Introduction</h3>
                      <h3 className="title">UI/UX Designer</h3>
                      <p className="desc">
                        I design and develop services for customers of all
                        sizes, specializing in creating stylish, modern
                        websites, web services and online stores.
                      </p>
                      <img src="img/signature.png" alt="" />
                    </div>
                    {/* <!-- /Main Title --> */}
                  </div>
                </div>
              </section>
              {/* <!-- /Home Section End--> */}
              <AboutSection />

              <PortfolioSection />

              <ServicesSection />

              <CustomerService />
              <NewsSection />

              <ContactSection />
            </div>

            <Footer />
          </div>

          <MainRightPart />
        </div>
        <RightHiddenNav />
        <div
          class="frenify-cursor cursor-outer"
          data-default="yes"
          data-link="yes"
          data-slider="yes"
        >
          <span class="fn-cursor"></span>
        </div>
        <div
          class="frenify-cursor cursor-inner"
          data-default="yes"
          data-link="yes"
          data-slider="yes"
        >
          <span class="fn-cursor">
            <span class="fn-left"></span>
            <span class="fn-right"></span>
          </span>
        </div>
      </div>
    </>
  );
}

export default Home;
