function AboutSection() {
  return (
    <>
      {/* <!-- About Section --> */}
      <section id="about">
        <div className="container">
          <div className="roww">
            {/* <!-- Main Title --> */}
            <div className="resumo_fn_main_title">
              <h3 className="subtitle">About Me</h3>
              <h3 className="title">Biography</h3>
              <p className="desc">
                I'm a Freelancer Front-end Developer with over 2 years of
                experience. I'm from Kampala Uganda. I code and create web
                elements for amazing people around the world. I like to work with
                new people. New people are new experiences.
              </p>
            </div>
            {/* <!-- /Main Title --> */}

            {/* <!-- About Information --> */}
            <div className="resumo_fn_about_info">
              <div className="about_left">
                <table>
                    <tbody>
                  <tr>
                    <th>Name</th>
                    <th>Ariho Shalot</th>
                  </tr>
                  <tr>
                    <th>Birthday</th>
                    <th>11th Novemebr 1995</th>
                  </tr>
                  <tr>
                    <th>Age</th>
                    <th>23 years</th>
                  </tr>
                  <tr>
                    <th>Address</th>
                    <th>Kampala Uganda</th>
                  </tr>
                  <tr>
                    <th>Phone</th>
                    <th>
                      <a href="tel:+3846923442364">(+256) 783975461</a>
                    </th>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <th>
                      <a href="arihoshalot1@gmail.com">
                        <span
                          className="__cf_email__"
                          data-cfemail="abcdd9cec5c2cdd2dfcecac6ebccc6cac2c785c8c4c6"
                        >
                          [email&#160;protected]
                        </span>
                      </a>
                    </th>
                  </tr>
                  <tr>
                    <th>Skype</th>
                    <th>
                      <a href="skype:brucewilson.90">arihoshalot.90</a>
                    </th>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div className="about_right">
                {/* <!-- Download CV Button --> */}
                <div className="resumo_fn_cv_btn">
                  <a href="/assets/img/cv.jpg" download>
                    <span className="icon">
                      <img src="assets/svg/inbox.svg" alt="" className="fn__svg" />
                      <img src="assets/svg/arrow.svg" alt="" className="fn__svg arrow" />
                    </span>
                    <span>Download CV</span>
                  </a>
                </div>
                {/* <!-- /Download CV Button --> */}
              </div>
            </div>
            {/* <!-- /About Information --> */}

            {/* <!-- Tabs Shortcode --> */}
            <div className="resumo_fn_tabs">
              {/* <!-- Tab: Header --> */}
              <div className="tab_header">
                <ul>
                  <li className="active">
                    <a href="#tab1">Experience</a>
                  </li>
                  <li>
                    <a href="#tab2">Education</a>
                  </li>
                  <li>
                    <a href="#tab3">Skills</a>
                  </li>
                </ul>
              </div>
              {/* <!-- /Tab: Header --> */}

              {/* <!-- Tab: Content --> */}
              <div className="tab_content">
                {/* <!-- #1 tab content --> */}
                <div id="tab1" className="tab_item active">
                  {/* <!-- Boxed List --> */}
                  <div className="resumo_fn_boxed_list">
                    <ul>
                      <li>
                        <div className="item">
                          <div className="item_top">
                            <h5>Youth Creative Agency</h5>
                            <span>( 2021  )</span>
                          </div>
                          <h3>Jr. Front-end Engineer</h3>
                          <p>
                            Worked as a Junior Front-end Engineer for Youth
                            Creative Agency. During my time there as an intern
                            {" "}
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="item">
                          <div className="item_top">
                            <h5>School Project </h5>
                            <span>( 2021 — 2022 )</span>
                          </div>
                          <h3>Project Lead </h3>
                          <p>
                            Lead a team of 4 people to create a school project
                            for a local school.
                            {" "}
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="item">
                          <div className="item_top">
                            <h5>Freelancer</h5>
                            <span>( 2021 — update )</span>
                          </div>
                          <h3>Graphic Designer</h3>
                          <p>
                            Side project for a freelance graphic designer.
                            {" "}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  {/* <!-- /Boxed List --> */}
                </div>
                {/* <!-- /#1 tab content --> */}

                {/* <!-- #2 tab content --> */}
                <div id="tab2" className="tab_item">
                  {/* <!-- Boxed List --> */}
                  <div className="resumo_fn_boxed_list">
                    <ul>
                      <li>
                        <div className="item">
                          <div className="item_top">
                            <h5>Multitech Business School</h5>
                            <span>( 2019— 2022 )</span>
                          </div>
                          <h3>Information Technology</h3>
                          <p>
                            Completing a degree in Information Technology.
                            {" "}
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="item">
                          <div className="item_top">
                            <h5>Edu BootCamp</h5>
                            <span>( 2021 — 2021 )</span>
                          </div>
                          <h3>Full Stack Developer</h3>
                          <p>
                            Attended a full stack developer bootcamp.
                            {" "}
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="item">
                          <div className="item_top">
                            <h5>QraftMind</h5>
                            <span>( 2021 — 2021 )</span>
                          </div>
                          <h3>Mentorship &amp; Product Design Program </h3>
                          <p>
                            Attended a mentorship and product design program.
                            {" "}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  {/* <!-- /Boxed List --> */}
                </div>
                {/* <!-- /#2 tab content --> */}

                {/* <!-- #3 tab content --> */}
                <div id="tab3" className="tab_item">
                  {/* <!-- Progress Bar --> */}
                  <div className="resumo_fn_progress_bar">
                    <div className="progress_item" data-value="92">
                      <div className="item_in">
                        <h3 className="progress_title">Adobe Photoshop</h3>
                        <span className="progress_percent"></span>
                        <div className="bg_wrap">
                          <div className="progress_bg"></div>
                        </div>
                      </div>
                    </div>

                    <div className="progress_item" data-value="95">
                      <div className="item_in">
                        <h3 className="progress_title">HTML5 &amp; CSS3</h3>
                        <span className="progress_percent"></span>
                        <div className="bg_wrap">
                          <div className="progress_bg"></div>
                        </div>
                      </div>
                    </div>

                    <div className="progress_item" data-value="80">
                      <div className="item_in">
                        <h3 className="progress_title">WordPress</h3>
                        <span className="progress_percent"></span>
                        <div className="bg_wrap">
                          <div className="progress_bg"></div>
                        </div>
                      </div>
                    </div>

                    <div className="progress_item" data-value="85">
                      <div className="item_in">
                        <h3 className="progress_title">Databases</h3>
                        <span className="progress_percent"></span>
                        <div className="bg_wrap">
                          <div className="progress_bg"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- /Progress Bar --> */}

                  {/* <!-- Description --> */}
                  <div className="resumo_fn_desc">
                    <p>
                      A freelance creative designer with a love for minimal
                      design, clean typography and well-written code, located in
                      San Francisco. Provide high quality and cost effective
                      offshore web and software development services. Wide range
                      of web and software development services across the world.
                    </p>
                  </div>
                </div>
                {/* <!-- /#2 tab content --> */}
              </div>
              {/* <!-- /Tab: Content --> */}
            </div>
            {/* <!-- /Tabs Shortcode --> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutSection;
